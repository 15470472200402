/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import { Homepage } from "../../../components/homepage"

export const pageQuery = graphql`
  query brno {
    file(relativePath: { eq: "parking-lotnisko-brno-front.jpeg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 585, height: 439)
      }
    }
    posts: allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___order_parking] }
      filter: { frontmatter: { template: { eq: "parking-post" } } }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            slug
            title
            isParkVia
            doNotShowOnMainPage
            featuredImage {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 345, height: 260)
              }
            }
          }
        }
      }
    }
  }
`

const frontmatter = {
  template: "index-page",
  slug: "/",
  title: "Parkingi Lotnisko Brno Czechy",
  tagline: "Brno Czechy, Polska",
  featuredImage: "/assets/parking-lotnisko-brno-front.jpeg",
  cta: {
    ctaText: "Wyszukaj parking",
    ctaLink: "#search",
  },
}
const HomePage = ({ data }) => (
  <Homepage
    frontmatter={frontmatter}
    data={data}
    descriptionComponent={
      <p>
        <b>Adres:</b> ul. Graniczna 190, 54-530 Brno Czechy Międzynarodowy Port
        Lotniczy im. Mikołaja Kopernika położony jest na terenie miasta Brno
        Czechyia, w odległości ok. 10 km. na zachód od jego centrum, na osiedlu
        Strachowice.
      </p>
    }
  >
    <h2>
      Parkingi przy lotnisku we Brno Czechy: Co warto wiedzieć przed podróżą?
    </h2>

    <p>
      Brno, będące drugim co do wielkości miastem w Czechach, posiada jedno z
      najbardziej nowoczesnych lotnisk w regionie.{" "}
      <strong>Lotnisko Brno</strong> jest kluczowym punktem komunikacyjnym dla
      wielu podróżnych, zarówno biznesowych, jak i turystycznych. W związku z
      tym parkingi na lotnisku Brno stały się niezbędną częścią infrastruktury.{" "}
    </p>
    <h2 id="-dlaczego-parking-na-lotnisku-brno-jest-tak-wa-ny-">
      <strong>Dlaczego parking na lotnisku Brno jest tak ważny?</strong>
    </h2>
    <p>
      Dla wielu osób podróż samolotem zaczyna się od parkingu. Wybór
      odpowiedniego miejsca do zaparkowania samochodu może wpłynąć na całą
      podróż. <strong>Bezpieczeństwo, dostępność i koszty</strong> to kluczowe
      czynniki, które należy wziąć pod uwagę przy wyborze parkingu na lotnisku.{" "}
    </p>
    <h2 id="-rodzaje-parking-w-dost-pnych-na-lotnisku-brno-">
      <strong>Rodzaje parkingów dostępnych na lotnisku Brno</strong>
    </h2>
    <h3 id="-d-ugoterminowy-parking-">
      <strong>Długoterminowy parking</strong>
    </h3>
    <p>
      Idealny dla osób, które planują dłuższą podróż. Parkingi długoterminowe na
      lotnisku Brno oferują konkurencyjne ceny i są zlokalizowane w bliskiej
      odległości od terminali.
    </p>
    <h3 id="-kr-tkoterminowy-parking-">
      <strong>Krótkoterminowy parking</strong>
    </h3>
    <p>
      Doskonały dla osób, które odbierają lub odprowadzają kogoś na lotnisko.
      Parkingi krótkoterminowe są zazwyczaj położone najbliżej terminali, co
      umożliwia szybki dostęp do budynku lotniska.
    </p>
    <h3 id="-parking-vip-">
      <strong>Parking VIP</strong>
    </h3>
    <p>
      Dla tych, którzy szukają luksusu i wygody. Parkingi VIP oferują dodatkowe
      usługi, takie jak osobisty portier czy pranie samochodu.
    </p>
    <h2 id="-bezpiecze-stwo-na-parkingach-lotniska-brno-">
      <strong>Bezpieczeństwo na parkingach lotniska Brno</strong>
    </h2>
    <p>
      Bezpieczeństwo jest priorytetem dla lotniska Brno. Wszystkie parkingi są
      monitorowane 24/7, a regularne patrole gwarantują, że Twój samochód będzie
      bezpieczny przez cały czas trwania podróży.
    </p>
    <h2 id="-rezerwacja-miejsca-parkingowego-online-">
      <strong>Rezerwacja miejsca parkingowego online</strong>
    </h2>
    <p>
      Dzięki nowoczesnym technologiom, teraz możesz zarezerwować miejsce
      parkingowe na lotnisku Brno online. To nie tylko oszczędza czas, ale także
      pozwala na znalezienie najlepszej oferty.
    </p>
    <h2 id="-podsumowanie-">
      <strong>Podsumowanie</strong>
    </h2>
    <p>
      Wybór odpowiedniego parkingu na lotnisku Brno jest kluczowy dla udanej
      podróży. Niezależnie od tego, czy szukasz miejsca na krótki czy długi
      okres, lotnisko Brno oferuje szeroką gamę opcji, które zaspokoją potrzeby
      każdego podróżnego.
    </p>
  </Homepage>
)

export default HomePage
